<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div class="data-grid">
        <dx-data-grid
          ref="refInventoryGrid"
          :data-source="receiptInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :height="gridHeight"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @toolbar-preparing="onToolbarPreparing"
          @cell-prepared="onCellPreparedOutput"
          @row-updated="onRowUpdated"
        >
          <DxEditing
            :allow-deleting="false"
            :allow-updating="true"
            :allow-adding="false"
            :use-icons="true"
            mode="cell"
          />
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            :fixed="false"
            caption="발생일"
            data-field="발생일"
            data-type="date"
            format="MM/dd"
            :min-width="columnWidth[0]"
          />
          <DxColumn
            :allow-editing="false"
            :fixed="false"
            caption="품번"
            data-field="품번"
            :min-width="columnWidth[1]"
          />
          <DxColumn
            :allow-editing="true"
            caption="불량원인"
            data-field="불량원인"
            :visible="true"
            :min-width="columnWidth[2]"
          >
            <DxLookup
              :data-source="badTypeLookup"
              display-expr="코드명"
              value-expr="코드"
            />
          </DxColumn>
          <DxColumn
            :allow-editing="false"
            caption="불량수량"
            data-field="불량수량"
            :visible="true"
            format="#,##0"
            :min-width="columnWidth[3]"
          />
          <DxColumn
            :allow-editing="false"
            caption="거래처"
            data-field="거래처명"
            :visible="true"
            :min-width="columnWidth[5]"
          />
          <DxColumn
            :allow-editing="false"
            caption="품명"
            data-field="품명"
            :visible="true"
            :min-width="columnWidth[6]"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              column="발생일자"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'
import DxDataGrid, {
  DxScrolling, DxColumnFixing, DxTotalItem, DxLookup,
  DxSummary, DxColumn, DxPaging, DxSorting, DxEditing
} from 'devextreme-vue/data-grid'

export default {
  name: 'WorkOrder',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxTotalItem,
    DxLookup,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxEditing
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      receiptInfoList: null,
      gridHeight: null,
      badTypeLookup: null,
      thisYear: null,
      yearArray: [],
      divisionList: null,
      columnWidth: [70, 140, 100, 100, 60, 100, 140]
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.badTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.불량원인)
    this.thisYear = AppLib.getThisYear()
    for (let i = this.thisYear; i >= 2021; i--) {
      this.yearArray.push({ year: i, yearText: `${i}년` })
    }
    this.refreshInventoryList('bad')
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 80)
    },
    onCellPreparedOutput (e) {
      if (e.rowType === 'data' && e.column.dataField === '처리상태') {
        if (e.data.처리상태 === 4) { // 반품
          this.$Q(e.cellElement).css('color', 'blue')
        } else if (e.data.처리상태 === 6) { // 폐기
          this.$Q(e.cellElement).css('color', 'red')
        }
      }
    },
    onRowUpdated (row) {
      this.isLoading = true
      row.data.updateid = this.userInfo.userName
      row.data.updatetime = AppLib.getNow()

      this.$_sp.runUpdateSqlProc('불량수불', ['seqId'], ['자재유형', '품목유형', '품명', '거래처명', '발생일'], [row.data])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 110,
            items: this.yearArray,
            valueExpr: 'year',
            displayExpr: 'yearText',
            value: this.thisYear,
            onValueChanged: (args) => {
              this.thisYear = args.value
              this.refreshInventoryList('bad')
            }
          }
        }
      )
    },
    refreshDivision () {
      this.$_sp.runNoEncodeFindSqlProc('부서정보', null)
        .then((data) => {
          this.divisionList = this.$_sp.MakeModel(data)
        })
    },
    refreshInventoryList (pType) {
      this.isLoading = true
      const param = {
        startDate: `${this.thisYear}-01-01`,
        endDate: `${this.thisYear}-12:31 23:59:59`,
        type: pType
      }
      this.$_sp.runNoEncodeFindProc('spFindAllInventoryReceiptByDateAndType', param)
        .then((data) => {
          this.isLoading = false
          this.receiptInfoList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .dx-toolbar-before {
    width: 300px!important;
  }
</style>
